footer {
  @extend %flex-dc;
  margin-top: auto;

  color: var(--color--light, #f0f0f0);
  background-color: var(--color--accent, #26354a);
}

.footer__top {
  @extend %relative;

  width: 100%;

  background-color: var(--color--light, #f0f0f0);

  @include breakpoint-max($mobile - 1) {
    padding-top: 60px;
  }
}

.footer__map {
  width: 100%;
  height: 480px;
}
.footer-contacts {
  @extend %absolute;
  top: 0;
  right: 8px;

  @extend %flex-ac-dc;
  width: 100%;
  max-width: calc(100% - 16px);
  padding: 16px 8px;

  background-color: #26354a;
  border-radius: var(--border-radius);

  @include breakpoint-min($tablet) {
    top: 8px;
    right: 8px;

    width: 440px;
    padding: 16px;
  }
}
.footer-contacts__list {
  @extend %flex-dc;
  justify-content: space-between;
  gap: 4px;

  @include breakpoint-min($mobile) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 0px;
    justify-content: space-between;
  }
}
.footer-contacts__item {
  &:nth-of-type(1) {
    grid-area: 1 / 1 / 3 / 2;
    min-width: 220px;
    max-width: 220px;
  }
  &:nth-of-type(2) {
    grid-area: 1 / 2 / 2 / 3;
  }
  &:nth-of-type(3) {
    grid-area: 2 / 2 / 3 / 3;
  }
}

.footer-contacts__link {
  color: inherit;
  font-size: 12px;

  &:hover {
    opacity: 0.8;
  }
}

.footer-keyword__list {
  @extend %flex-ac;
  gap: 4px;
  margin-bottom: 20px;
}
.footer-keyword__item {
  @extend %flex-ac;
  gap: 4px;

  font-size: 12px;

  &::before {
    content: '';

    display: block;
    width: 4px;
    height: 4px;

    background-color: var(--color--light, #f0f0f0);
    border-radius: 50%;
  }
}

.footer__bottom {
  width: 100%;
  padding: 16px 0;
}
.footer__bottom .footer__container {
  @extend %flex-ac;
  justify-content: flex-end;
  gap: 8px;

  @include breakpoint-max($tablet - 1) {
    flex-direction: column-reverse;
  }
}

.footer-nav__list {
  @extend %flex-ac;
  gap: 8px;

  @include breakpoint-max($tablet - 1) {
    flex-direction: column;
  }
}
.footer-nav__item {
  @extend %flex-ac;
  gap: 8px;

  @include breakpoint-min($tablet) {
    &::before {
      content: '/';
    }
  }
}
.footer-nav__link {
  text-decoration: underline;

  &:hover {
    opacity: 0.8;
  }
}
