.header {
  @extend %fixed-tl;
  
  @extend %flex-ac;
  width: 100%;
  min-height: 80px;
  max-height: 80px;

  color: var(--color--light, #f0f0f0);

  background-color: var(--color--accent, #26354a);
  @extend %transition--300;
  z-index: 3;

  @include breakpoint-min($tablet) {
    min-height: 100px;
    max-height: 100px;
  }
  @include breakpoint-min($laptop) {
    min-height: 120px;
    max-height: 120px;
  }
}

.header--dark {
  color: var(--color--light, #f0f0f0);
  background-color: var(--color--accent, #26354a);
}
.header--light {
  min-height: 40px;
  max-height: 40px;

  color: var(--color--accent, #26354a);
  background-color: #fff;
  @extend %box-shadow-main;
}

.header__container {
  @extend %relative;

  @extend %flex-ac-jsb;
  gap: 16px;
}
