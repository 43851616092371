.backdrop {
  @extend %fixed-tlrb;
  @extend %flex-ac-jc;
  align-items: flex-start;
  padding: 16px;

  background-color: rgba(38, 53, 74, 0.95);
  z-index: 3000;
  overflow: scroll;


  @media screen and (min-width: 1024px) and (orientation: landscape) and (min-height: 1024px) {
    align-items: center;
  }
}

// @media screen and (min-aspect-ratio: 4 / 3) {
//   align-items: center;
// }

  // @media screen and (max-width: 1023px) and (orientation: landscape) {
  //   align-items: flex-start;
  // }
