.chat {
  position: sticky;
  bottom: 0;

  @extend %flex-ac-jsb;
  gap: 20px;
  padding: 16px;
  margin-top: auto;

  background-color: transparent;
  z-index: 2;
}

.chat__link {
  flex: 0 0 48px;
  @extend %flex-ac-jc;
  width: 48px;
  height: 48px;

  color: var(--color--light, #f0f0f0);

  border-radius: 50%;
  @extend %box-shadow-active;
  @extend %transition--300;
}
.chat__link:hover,
.chat__link:focus {
  transform: scale(1.1);
}
.chat__link--whatsapp {
  background-color: #25d366;
}

.chat__icon {
  width: 30px;
  height: 30px;
}

.chat__link-contact {
  @extend %flex-ac-jc;
  height: 48px;
  width: 240px;
  padding: 0 12px;

  color: var(--color--light, #f0f0f0);
  font-weight: 600;
  text-transform: uppercase;

  background-color: var(--color--accent, #26354a);
  border-radius: calc(var(--border-radius) * 2);
  border: 1px solid var(--color--accent, #26354a);
  @extend %box-shadow-active;
  @extend %transition--200;

  &:hover {
    color: var(--color--accent, #26354a);
    background-color: var(--color--gray, #e0e7ef);
  }
}
