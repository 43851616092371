@import '/src/common/scss/reset.scss';
@import '/src/common/scss/var.scss';
@import '/src/common/scss/utils.scss';
@import '/src/common/scss/backdrop.scss';
@import '/src/components/loader/loader.scss';
@import '/src/components/logo/logo.scss';
@import '/src/components/modal/modal.scss';

// * { outline: 1px solid gold; }

html {
  scroll-behavior: smooth;
  scroll-padding-top: 80px;

  @include breakpoint-min($tablet) {
    scroll-padding-top: 100px;
  }
  @include breakpoint-min($laptop) {
    scroll-padding-top: 120px;
  }

  &.header--light {
    scroll-padding-top: 48px;
  }
}

body.isLoading,
body.modalIsOpen,
body.burgerMenuIsOpen {
  @extend %overflow-h;
}

body {
  @extend %relative;
  
  @extend %flex-dc;
  min-height: 100vh;

  color: var(--color--accent, #26354a);
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: normal;

  background-color: var(--color--light, #f0f0f0);
  @extend %transition--200;
}
main {
  flex-grow: 1;
  @extend %flex-dc;
  padding-top: 80px;

  @extend %transition--200;
  
  @include breakpoint-min($tablet) {
    padding-top: 100px;
  }
  @include breakpoint-min($laptop) {
    padding-top: 120px;
  }
}
footer {
  margin-top: auto;
}

svg {
  fill: currentColor;
  pointer-events: none;
}
button,
a {
  @extend %pointer;

  // &:focus-visible { outline: 2px solid var(--color--accent, #26354a); }
}

.section {
  padding: 16px 0;

  @include breakpoint-min($tablet) {
    padding: 32px 0;
  }
}
.container {
  width: 100%;
  padding: 0 16px;
  margin: 0 auto;

  @include breakpoint-min($mobile) {
    width: $mobile;
  }
  @include breakpoint-min($tablet) {
    width: $tablet;
    padding: 0 32px;
  }
  @include breakpoint-min($laptop) {
    width: $laptop;
  }
  @include breakpoint-min($desktop) {
    width: $desktop;
  }
}

.title {
  margin-bottom: 28px;

  font-size: 24px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;

  @include breakpoint-min($tablet) {
    margin-bottom: 40px;
    font-size: 28px;
  }
  @include breakpoint-min($laptop) {
    font-size: 32px;
  }
}
.subtitle {
  font-family: var(--font--title);
  font-size: 24px;

  @include breakpoint-min($desktop) {
    font-size: 28px;
  }
}
.section-title {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  // text-transform: uppercase;
  @include breakpoint-min($tablet) {
    font-size: 24px;
  }
  @include breakpoint-min($laptop) {
    font-size: 28px;
  }
}

.list {
  list-style: none;
}
.link {
  text-decoration: none;
  color: inherit;
}

.items--1-2-3 {
  @include breakpoint-min($tablet) {
    --items: 2;
  }
}

@import '/src/components/nav/nav.scss';
@import '/src/components/social-media/social-media.scss';
@import '/src/components/header/header.scss';
@import '/src/components/footer/footer.scss';
@import '/src/components/burger-menu/burger-menu.scss';
@import '/src/components/btns/btn-chat/btn-chat.scss';