@import '/src/components/btns/btn-cross/btn-cross.scss';

.modal__backdrop {
  @extend %transition--100;

  .modal-offers.isHidden & {
    @extend %pvo;
  }
}

.modal__body {
  width: 100%;
  margin: 0 auto;
  padding: 16px;

  background-color: var(--color--light, #f0f0f0);
  @extend %border-radius;
  @extend %transition--200;

  .modal-offers.isHidden & {
    transform: scale(0.8);
  }

  @include breakpoint-min($tablet) {
    max-width: $tablet;
  }
}

// .modal__header,
// .modal__main,
// .modal__footer {
//   // padding: 12px;
// }

.modal__btn--close {
  margin-left: auto;
}
