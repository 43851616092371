.logo {
  flex-shrink: 0;
}

.logo__icon {
  width: auto;
  height: 60px;
  object-fit: contain;
  @extend %transition--200;

  fill: currentColor;

  @include breakpoint-min($tablet) {
    height: 80px;
  }
  @include breakpoint-min($laptop) {
    height: 100px;
  }

  .header--light & {
    height: 32px;
  }
}

// .logo__img {
//   height: 60px;
//   width: auto;

//   @extend %transition--300;

//   &.logo__img--light {
//     @extend %absolute;

//     opacity: 0;
//   }

//   .header--light & {
//     &.logo__img--dark { opacity: 0; }
//     &.logo__img--light { opacity: 1; }

//     height: 32px;
//   }

//   @include breakpoint-min($tablet) {
//     height: 80px;
//   }
//   @include breakpoint-min($laptop) {
//     height: 100px;
//   }
// }
