@import '/src/components/burger-menu/burger-menu-btn.scss';

.burger-menu {
  @extend %fixed-tl;

  @extend %flex-dc;
  width: 100%;
  height: 100%;
  padding-top: 80px;

  color: var(--color--light, #f0f0f0);
  background-color: var(--color--accent, #26354a);

  @extend %transition--200;
  overflow-y: scroll;
  z-index: 1;

  &.isHidden {
    // top: -101%;
    left: 101%;

    button, a { @extend %pvo; }
  }
}

.burger-menu__body {
  @extend %flex-ac-dc;
  gap: 32px;
}

.burger-menu__footer {
  margin-top: auto;
}
