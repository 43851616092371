.header__nav {
  @include breakpoint-max($tablet - 1) {
    display: none;
  }

  @include breakpoint-max($laptop - 1) {
    margin: auto 0 8px;
  }
}

.nav__list {
  @extend %flex-ac;
  gap: 12px;
}
.burger-menu__nav .nav__list {
  @extend %flex-dc;
}

.nav__link {
  &.active {
    text-decoration: underline;
  }

  &:hover {
    opacity: 0.8;
  }
}
