.burger-menu__btn {
  @extend %relative;

  @extend %flex-ac-jc;
  flex: 0 0 40px;
  width: 40px;
  height: 40px;

  color: inherit;
  background-color: transparent;

  body.burgerMenuIsOpen & {
    z-index: 3;
    color: var(--color--light, #f0f0f0);
  }

  @include breakpoint-min($tablet) {
    display: none;
  }
}

.burger-menu__btn .burger-line {
  @extend %absolute;

  width: 28px;
  height: 2px;
  margin: 0;

  @extend %border-radius;
  background-color: currentColor;
  transition-property: height, opacity, transform;
  transition-duration: 0.4s;
}

.burger-line:first-child {
  transform: translateY(8px);
}
.burger-line:last-child {
  transform: translateY(-8px);
}

body.burgerMenuIsOpen .burger-menu__btn {
  .burger-line:first-child {
    transform: rotate(-45deg);
  }
  .burger-line:nth-child(2n) {
    opacity: 0;
  }
  .burger-line:last-child {
    transform: rotate(45deg);
  }
}
