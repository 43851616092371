.header__social-media {
  @extend %transition--300;

  @include breakpoint-max($tablet - 1) {
    display: none;
  }
  @include breakpoint-max($laptop - 1) {
    position: absolute;
    top: 0;
    right: 32px;

    .header--light & {
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
    }
  }
}


.social-media__list {
  @extend %flex-ac;
  gap: 9px;
}
.social-media__link {
  flex: 0 0 32px;
  @extend %flex-ac-jc;
  width: 32px;
  height: 32px;

  color: var(--color--light, #f0f0f0);

  background-color: var(--color--accent, #26354a);
  border: 1px solid var(--color--light, #f0f0f0);
  border-color: #fff;
  @extend %border-radius;
  @extend %transition--100;
  
  &:hover {
    @extend %box-shadow-active;

    color: var(--color--accent, #26354a);
    background-color: var(--color--light, #f0f0f0);
  }
  .header--light &:hover {
    @extend %box-shadow-active;

    color: var(--color--accent, #26354a);
    background-color: var(--color--light, #f0f0f0);
    border-color: var(--color--accent, #26354a);
  }
}

.social-media__icon {
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
}
.social-media__icon--messenger {
  flex: 0 0 30px;
  width: 30px;
  height: 30px;
}


