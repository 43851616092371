*, :before, :after {
  box-sizing: border-box;
}

* {
  border: none;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  line-height: normal;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

img, picture, video, canvas, svg, iframe {
  max-width: 100%;
  display: block;
}

img {
  vertical-align: top;
}

ol, ul, li {
  list-style: none;
}

input, button, textarea, select {
  font: inherit;
}

button[disabled] {
  cursor: auto;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

a {
  text-decoration: none;
}

:root {
  --color--light: #f0f0f0;
  --color--dark: #000;
  --color--accent: #26354a;
  --color--gray: #e0e7ef;
  --color--red: #900;
  --transition--100: .1s linear;
  --transition--200: .2s linear;
  --transition--300: .3s linear;
  --transition--400: .4s linear;
  --transition--500: .5s linear;
  --box-shadow: 0 2px 2px #00000038;
  --box-shadow--main: 0 .5px 1px #0000001a, 0 2px 6px #5a646d33;
  --box-shadow--active: 0 0 8px -2px #0000001a, 0 6px 20px -3px #0003;
  --items: 1;
  --gap: 16px;
  --index: calc(1vh + 1vw);
  --border-radius: 8px;
  --text-indent: 40px;
}

.footer-nav__item, .footer-nav__list, .footer__bottom .footer__container, .footer-keyword__item, .footer-keyword__list, .header, .social-media__list, .nav__list, .btn-cross, .burger-menu__body, .footer-contacts, .chat, .header__container, .chat__link-contact, .chat__link, .burger-menu__btn, .social-media__link, .backdrop {
  align-items: center;
  display: flex;
}

.chat__link-contact, .chat__link, .burger-menu__btn, .social-media__link, .backdrop {
  justify-content: center;
}

.chat, .header__container {
  justify-content: space-between;
}

.burger-menu, .footer-contacts__list, footer, .burger-menu__nav .nav__list, main, body {
  flex-direction: column;
  display: flex;
}

.burger-menu__body, .footer-contacts {
  flex-direction: column;
}

.burger-menu__btn, .footer__top, .header__container, body, .btn-cross, .lds-roller {
  position: relative;
}

.burger-menu__btn .burger-line, .footer-contacts, .btn-cross:before, .btn-cross:after, .lds-roller div:after, .visually-hidden {
  position: absolute;
}

.burger-menu, .header, .backdrop {
  position: fixed;
}

.backdrop {
  inset: 0;
}

.burger-menu, .header {
  top: 0;
  left: 0;
}

button, a, .btn-cross {
  cursor: pointer;
}

.burger-menu__btn .burger-line, .social-media__link, .modal__body, .btn-cross:before, .btn-cross:after {
  border-radius: var(--border-radius);
}

.header--light {
  box-shadow: var(--box-shadow--main);
}

.chat__link-contact, .chat__link, .header--light .social-media__link:hover, .social-media__link:hover {
  box-shadow: var(--box-shadow--active);
}

.social-media__link, .modal__backdrop {
  transition: var(--transition--100);
}

.chat__link-contact, .burger-menu, main, body, .modal__body, .logo__icon {
  transition: var(--transition--200);
}

.chat__link, .header, .header__social-media {
  transition: var(--transition--300);
}

body.isLoading, body.modalIsOpen, body.burgerMenuIsOpen, .visually-hidden {
  overflow: hidden;
}

.burger-menu.isHidden button, .burger-menu.isHidden a, .modal-offers.isHidden .modal__backdrop {
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
}

.visually-hidden {
  white-space: nowrap;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  border: 0;
  margin: -1px;
  padding: 0;
}

.backdrop {
  z-index: 3000;
  background-color: #26354af2;
  align-items: flex-start;
  padding: 16px;
  overflow: scroll;
}

@media screen and (min-width: 1024px) and (orientation: landscape) and (min-height: 1024px) {
  .backdrop {
    align-items: center;
  }
}

.loader__backdrop {
  align-items: center;
}

.isHidden .loader__backdrop {
  display: none;
}

.lds-roller {
  width: 80px;
  height: 80px;
  display: inline-block;
}

.lds-roller div {
  transform-origin: 40px 40px;
  animation: 1.2s cubic-bezier(.5, 0, .5, 1) infinite lds-roller;
}

.lds-roller div:after {
  content: " ";
  width: 7px;
  height: 7px;
  background: #f0f0f0;
  border-radius: 50%;
  margin: -4px 0 0 -4px;
  display: block;
}

.lds-roller div:nth-child(1) {
  animation-delay: -.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -.18s;
}

.lds-roller div:nth-child(5):after {
  background: var(--color--red, #900);
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.logo {
  flex-shrink: 0;
}

.logo__icon {
  width: auto;
  height: 60px;
  object-fit: contain;
  fill: currentColor;
}

@media (min-width: 768px) {
  .logo__icon {
    height: 80px;
  }
}

@media (min-width: 1024px) {
  .logo__icon {
    height: 100px;
  }
}

.header--light .logo__icon {
  height: 32px;
}

.btn-cross {
  width: 24px;
  height: 24px;
  color: inherit;
  background-color: #0000;
}

.btn-cross:before, .btn-cross:after {
  content: "";
  width: 20px;
  height: 2px;
  background-color: currentColor;
}

.btn-cross:before {
  transform: rotate(-45deg);
}

.btn-cross:after {
  transform: rotate(45deg);
}

.modal__body {
  width: 100%;
  background-color: var(--color--light, #f0f0f0);
  margin: 0 auto;
  padding: 16px;
}

.modal-offers.isHidden .modal__body {
  transform: scale(.8);
}

@media (min-width: 768px) {
  .modal__body {
    max-width: 768px;
  }
}

.modal__btn--close {
  margin-left: auto;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 80px;
}

@media (min-width: 768px) {
  html {
    scroll-padding-top: 100px;
  }
}

@media (min-width: 1024px) {
  html {
    scroll-padding-top: 120px;
  }
}

html.header--light {
  scroll-padding-top: 48px;
}

body {
  min-height: 100vh;
  color: var(--color--accent, #26354a);
  background-color: var(--color--light, #f0f0f0);
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  line-height: normal;
}

main {
  flex-grow: 1;
  padding-top: 80px;
}

@media (min-width: 768px) {
  main {
    padding-top: 100px;
  }
}

@media (min-width: 1024px) {
  main {
    padding-top: 120px;
  }
}

footer {
  margin-top: auto;
}

svg {
  fill: currentColor;
  pointer-events: none;
}

.section {
  padding: 16px 0;
}

@media (min-width: 768px) {
  .section {
    padding: 32px 0;
  }
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
}

@media (min-width: 480px) {
  .container {
    width: 480px;
  }
}

@media (min-width: 768px) {
  .container {
    width: 768px;
    padding: 0 32px;
  }
}

@media (min-width: 1024px) {
  .container {
    width: 1024px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1200px;
  }
}

.title {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 28px;
  font-size: 24px;
  font-weight: 600;
}

@media (min-width: 768px) {
  .title {
    margin-bottom: 40px;
    font-size: 28px;
  }
}

@media (min-width: 1024px) {
  .title {
    font-size: 32px;
  }
}

.subtitle {
  font-family: var(--font--title);
  font-size: 24px;
}

@media (min-width: 1200px) {
  .subtitle {
    font-size: 28px;
  }
}

.section-title {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

@media (min-width: 768px) {
  .section-title {
    font-size: 24px;
  }
}

@media (min-width: 1024px) {
  .section-title {
    font-size: 28px;
  }
}

.list {
  list-style: none;
}

.link {
  color: inherit;
  text-decoration: none;
}

@media (min-width: 768px) {
  .items--1-2-3 {
    --items: 2;
  }
}

@media (max-width: 767px) {
  .header__nav {
    display: none;
  }
}

@media (max-width: 1023px) {
  .header__nav {
    margin: auto 0 8px;
  }
}

.nav__list {
  gap: 12px;
}

.nav__link.active {
  text-decoration: underline;
}

.nav__link:hover {
  opacity: .8;
}

@media (max-width: 767px) {
  .header__social-media {
    display: none;
  }
}

@media (max-width: 1023px) {
  .header__social-media {
    position: absolute;
    top: 0;
    right: 32px;
  }

  .header--light .header__social-media {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
}

.social-media__list {
  gap: 9px;
}

.social-media__link {
  width: 32px;
  height: 32px;
  color: var(--color--light, #f0f0f0);
  background-color: var(--color--accent, #26354a);
  border: 1px solid var(--color--light, #f0f0f0);
  border-color: #fff;
  flex: 0 0 32px;
}

.social-media__link:hover {
  color: var(--color--accent, #26354a);
  background-color: var(--color--light, #f0f0f0);
}

.header--light .social-media__link:hover {
  color: var(--color--accent, #26354a);
  background-color: var(--color--light, #f0f0f0);
  border-color: var(--color--accent, #26354a);
}

.social-media__icon {
  width: 20px;
  height: 20px;
  flex: 0 0 20px;
}

.social-media__icon--messenger {
  width: 30px;
  height: 30px;
  flex: 0 0 30px;
}

.header {
  width: 100%;
  min-height: 80px;
  max-height: 80px;
  color: var(--color--light, #f0f0f0);
  background-color: var(--color--accent, #26354a);
  z-index: 3;
}

@media (min-width: 768px) {
  .header {
    min-height: 100px;
    max-height: 100px;
  }
}

@media (min-width: 1024px) {
  .header {
    min-height: 120px;
    max-height: 120px;
  }
}

.header--dark {
  color: var(--color--light, #f0f0f0);
  background-color: var(--color--accent, #26354a);
}

.header--light {
  min-height: 40px;
  max-height: 40px;
  color: var(--color--accent, #26354a);
  background-color: #fff;
}

.header__container {
  gap: 16px;
}

footer {
  color: var(--color--light, #f0f0f0);
  background-color: var(--color--accent, #26354a);
  margin-top: auto;
}

.footer__top {
  width: 100%;
  background-color: var(--color--light, #f0f0f0);
}

@media (max-width: 479px) {
  .footer__top {
    padding-top: 60px;
  }
}

.footer__map {
  width: 100%;
  height: 480px;
}

.footer-contacts {
  width: 100%;
  max-width: calc(100% - 16px);
  border-radius: var(--border-radius);
  background-color: #26354a;
  padding: 16px 8px;
  top: 0;
  right: 8px;
}

@media (min-width: 768px) {
  .footer-contacts {
    width: 440px;
    padding: 16px;
    top: 8px;
    right: 8px;
  }
}

.footer-contacts__list {
  justify-content: space-between;
  gap: 4px;
}

@media (min-width: 480px) {
  .footer-contacts__list {
    grid-column-gap: 20px;
    grid-row-gap: 0px;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    display: grid;
  }
}

.footer-contacts__item:nth-of-type(1) {
  min-width: 220px;
  max-width: 220px;
  grid-area: 1 / 1 / 3 / 2;
}

.footer-contacts__item:nth-of-type(2) {
  grid-area: 1 / 2 / 2 / 3;
}

.footer-contacts__item:nth-of-type(3) {
  grid-area: 2 / 2 / 3 / 3;
}

.footer-contacts__link {
  color: inherit;
  font-size: 12px;
}

.footer-contacts__link:hover {
  opacity: .8;
}

.footer-keyword__list {
  gap: 4px;
  margin-bottom: 20px;
}

.footer-keyword__item {
  gap: 4px;
  font-size: 12px;
}

.footer-keyword__item:before {
  content: "";
  width: 4px;
  height: 4px;
  background-color: var(--color--light, #f0f0f0);
  border-radius: 50%;
  display: block;
}

.footer__bottom {
  width: 100%;
  padding: 16px 0;
}

.footer__bottom .footer__container {
  justify-content: flex-end;
  gap: 8px;
}

@media (max-width: 767px) {
  .footer__bottom .footer__container {
    flex-direction: column-reverse;
  }
}

.footer-nav__list {
  gap: 8px;
}

@media (max-width: 767px) {
  .footer-nav__list {
    flex-direction: column;
  }
}

.footer-nav__item {
  gap: 8px;
}

@media (min-width: 768px) {
  .footer-nav__item:before {
    content: "/";
  }
}

.footer-nav__link {
  text-decoration: underline;
}

.footer-nav__link:hover {
  opacity: .8;
}

.burger-menu__btn {
  width: 40px;
  height: 40px;
  color: inherit;
  background-color: #0000;
  flex: 0 0 40px;
}

body.burgerMenuIsOpen .burger-menu__btn {
  z-index: 3;
  color: var(--color--light, #f0f0f0);
}

@media (min-width: 768px) {
  .burger-menu__btn {
    display: none;
  }
}

.burger-menu__btn .burger-line {
  width: 28px;
  height: 2px;
  background-color: currentColor;
  margin: 0;
  transition-property: height, opacity, transform;
  transition-duration: .4s;
}

.burger-line:first-child {
  transform: translateY(8px);
}

.burger-line:last-child {
  transform: translateY(-8px);
}

body.burgerMenuIsOpen .burger-menu__btn .burger-line:first-child {
  transform: rotate(-45deg);
}

body.burgerMenuIsOpen .burger-menu__btn .burger-line:nth-child(2n) {
  opacity: 0;
}

body.burgerMenuIsOpen .burger-menu__btn .burger-line:last-child {
  transform: rotate(45deg);
}

.burger-menu {
  width: 100%;
  height: 100%;
  color: var(--color--light, #f0f0f0);
  background-color: var(--color--accent, #26354a);
  z-index: 1;
  padding-top: 80px;
  overflow-y: scroll;
}

.burger-menu.isHidden {
  left: 101%;
}

.burger-menu__body {
  gap: 32px;
}

.burger-menu__footer {
  margin-top: auto;
}

.chat {
  z-index: 2;
  background-color: #0000;
  gap: 20px;
  margin-top: auto;
  padding: 16px;
  position: sticky;
  bottom: 0;
}

.chat__link {
  width: 48px;
  height: 48px;
  color: var(--color--light, #f0f0f0);
  border-radius: 50%;
  flex: 0 0 48px;
}

.chat__link:hover, .chat__link:focus {
  transform: scale(1.1);
}

.chat__link--whatsapp {
  background-color: #25d366;
}

.chat__icon {
  width: 30px;
  height: 30px;
}

.chat__link-contact {
  height: 48px;
  width: 240px;
  color: var(--color--light, #f0f0f0);
  text-transform: uppercase;
  background-color: var(--color--accent, #26354a);
  border-radius: calc(var(--border-radius) * 2);
  border: 1px solid var(--color--accent, #26354a);
  padding: 0 12px;
  font-weight: 600;
}

.chat__link-contact:hover {
  color: var(--color--accent, #26354a);
  background-color: var(--color--gray, #e0e7ef);
}

/*# sourceMappingURL=index.7254306a.css.map */
