.btn-cross {
  @extend %relative;

  @extend %flex-ac;
  width: 24px;
  height: 24px;

  color: inherit;

  background-color: transparent;
  @extend %pointer;
}

.btn-cross::before,
.btn-cross::after {
  content: '';
  @extend %absolute;

  width: 20px;
  height: 2px;

  background-color: currentColor;
  @extend %border-radius;
}

.btn-cross::before {
  // @extend %absolute;
  transform: rotate(-45deg);
  transform: translateY(8px);
}

.btn-cross::after {
  // @extend %absolute;
  transform: rotate(45deg);
  transform: translateY(-8px);
}
